import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Images/gymlogo-cropped.jpeg'; // Update this path to your logo
import '../Navbar.css'; // Import the CSS file

const Navbar = () => {
    const navRef = useRef(null);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (navRef.current && !navRef.current.contains(event.target)) {
    //             const collapseElement = document.getElementById('navbarNav');
    //             if (collapseElement.classList.contains('show')) {
    //                 collapseElement.classList.remove('show');
    //             }
    //         }
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-black border-bottom border-body" data-bs-theme="dark" ref={navRef} style={{ opacity: 0.9 }}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="Logo" style={{ height: '100px', width: '100px', background: 'none', margin: '0 auto', borderRadius: '40%' }} />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/About">About</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/Facilities">Facilities</Link>
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/Service">Facilities & Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/Facilities">Gallery</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
