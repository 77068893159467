import React from 'react';
// import ggallery1 from '../Images/ggallery1.jpeg';
// import ggallery2 from '../Images/ggallery2.jpeg';
// import ggallery3 from '../Images/ggallery3.jpeg';
// import ggallery4 from '../Images/ggallery4.jpeg';
// import ggallery5 from '../Images/ggallery5.jpeg';
// import ggallery6 from '../Images/ggallery6.jpeg';
// import ggallery7 from '../Images/ggallery7.jpeg';
import ggallery8 from '../Images/ggallery8.jpeg';
import ggallery9 from '../Images/ggallery9.jpeg';
import ggallery10 from '../Images/ggallery10.jpeg';
import ggallery11 from '../Images/ggallery11.jpeg';
import ggallery12 from '../Images/ggallery12.jpeg';
import DSC_5226 from '../Images/DSC_5226.jpg';
import DSC_5227 from '../Images/DSC_5227.jpg';

import '../Facilities.css';

const facilitiesData = [
    // { img: ggallery1, text: 'Strength Workout' },
    // { img: ggallery2, text: 'Strength Workout' },
    // { img: ggallery3, text: 'Strength Workout' },
    // { img: ggallery4, text: 'Strength Workout' },
    // { img: ggallery5, text: 'Strength Workout' },
    // { img: ggallery6, text: 'Strength Workout' },
    // { img: ggallery7, text: 'Strength Workout' },
    { img: ggallery8, text: 'Strength Workout' },
    { img: ggallery9, text: 'Strength Workout' },
    { img: ggallery10, text: 'Strength Workout' },
    { img: ggallery11, text: 'Strength Workout' },
    { img: ggallery12, text: 'Strength Workout' },
    { img: DSC_5226, text: 'Strength Workout' },
    // { img: DSC_5227, text: 'Strength Workout' },
    
];

const Facilities = () => {                  
    return (
        <div className="facilities-container">
            <h1 className="facilities-title">Gallery</h1>
            <p className="facilities-description"></p>
            <div className="facilities-grid">
                {facilitiesData.map((facility, index) => (
                    <div key={index} className="facility-card">
                        <img 
                            src={facility.img} 
                            alt={facility.text} 
                            className="facility-image" 
                        />
                        {/* <p className="facility-text">{facility.text}</p> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Facilities;
