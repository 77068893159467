import React, { useState, useEffect } from 'react';
import Feelingempowered from '../Images/Feelingempowered.webp';
import IgniteYour from '../Images/IgniteYour.jpg';
import Timetocrush from '../Images/Timetocrush.webp';
// import TrainHard from '../Images/TrainHard.webp';
import Turnyourdoubts from '../Images/Turnyourdoubts.png';


const images = [
  { img: Feelingempowered },
  { img: IgniteYour },
  { img: Timetocrush },
  // { img: TrainHard },
  { img: Turnyourdoubts }
];

const Hello = () => {
  const [curr, setCurr] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurr(curr => (curr === images.length - 1 ? 0 : curr + 1));
    }, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurr(curr === images.length - 1 ? 0 : curr + 1);
  };

  const prevSlide = () => {
    setCurr(curr === 0 ? images.length - 1 : curr - 1);
  };

  return (
    <div className='hero-img' style={{ maxWidth: '100%',maxHeight:"", margin: 'auto', marginTop: '10px', marginBottom: '10px',position: 'relative' }}>
      <div>
        {images.map((value, index) => (
          <div
            key={index}
            style={{
              display: curr === index ? 'block' : 'none',
              transition: 'opacity 1s ease-in-out'
            }}
          >
            <img
              src={value.img} 
              style={{ 
                width: '100%',                   
                objectFit: 'cover', 
                borderRadius: '1px' 
              }} 
              alt={`Slide ${index + 1}`} 
            />
          </div>
        ))}
      </div>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurr(index)}
            style={{
              backgroundColor: curr === index ? '#333' : '#bbb',
              border: 'none',
              borderRadius: '50%',
              display: 'inline-block',
              width: '12px',
              height: '12px',
              margin: '0 2px',
              cursor: 'pointer'
            }}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
      <button
        onClick={prevSlide}
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          border: 'none',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        aria-label="Previous slide"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          border: 'none',
          borderRadius: '50%',
          color: 'white',
          cursor: 'pointer',
          width: '30px',
          height: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        aria-label="Next slide"
      >
        &#10095;
      </button>
      
    </div>
  );
};

export default Hello;
