import React from 'react';
import { Link } from 'react-router-dom';
import strenth_training from '../Images/strenth_training.jpeg'; 
import IMG_20230727_204530 from '../Images/IMG_20230727_204530.jpg';
import Findingbalance from '../Images/Findingbalance.webp';
import DSC_6190 from '../Images/DSC_6190.jpg';
import aerobic from '../Images/aerobic.jpeg';
import karate from '../Images/karate.jpeg';  
import trx from '../Images/trx.jpg';
import DSC_6306 from '../Images/DSC_6306.jpg';
import steambath from '../Images/steambath.jpg';
import cardio0 from '../Images/cardio0.jpeg';
import cardio from '../Images/cardio.jpeg';
import kickboxing from '../Images/kickboxing.jpg';
import abbsImage from  '../Images/Abbs-image.webp'


const arr = [
  {
    img: strenth_training,
    title: "Strength Workout"
  },
  {
    img: cardio0,
    title: "Cardio Training"
  },
  {
    img: IMG_20230727_204530,
    title: "Group Training"
  },
  {
    img: aerobic,
    title: "Aerobics Session"
  },
  {
    img: DSC_6190,
    title: "Zumba Session"
  },
  {
    img: Findingbalance,
    title: "Yoga Session"
  },
  {
    img: karate,
    title: "Karate Martial Arts"
  },
  {
    img: kickboxing,
    title: "Kick Boxing"
  },
  {
    img: trx,
    title: "TRX"
  },
  {
    img: DSC_6306,
    title: "CrossFit Training"
  },
  {
    img: steambath,
    title: "Steam Bath"
  },
  {
    img: cardio,
    title: "Spin Bike"
  },
  {
   img: abbsImage,
   title:"Crossfit and Floor Abs"
  }
  // Add more items as needed
];

const Service = () => {
  return (
    <>
      <div style={{ width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <p style={{ fontSize: '24px', fontWeight: 'bold' }}>FACILITY & SERVICES</p>
      </div>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '20px', padding: '2px', backgroundColor: 'white', marginBottom: '20px' }}>
        {
          arr.map((item, index) => (
            <Link key={index} to={`/service/${item.title}`} style={{ width: '100%', maxWidth: '400px', height: '364px', position: 'relative', border: '5px solid #ddd', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', textDecoration: 'none' }}>
              <div style={{ width: '100%', height: '100%', backgroundImage: `url(${item.img})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
              <div style={{ width: '100%', height: '40px', backgroundColor: 'orange', zIndex: '1', position: 'absolute', bottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'black' }}>
                {item.title}
              </div>
            </Link>
          ))
        }
      </div>
    </>
  )
}

export default Service;
