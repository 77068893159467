import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import gymlogo from '../Images/gymlogo-cropped.jpeg';

const Footer = () => {
    return (
        <footer style={styles.footer}>
            <div style={styles.column}>
                <img src={gymlogo} alt="Logo" style={styles.logo} />
                <h4 style={styles.addressHeader}>Address:</h4>
                <p style={styles.description}>
                    Infinity Fitness<br/>
                    4th floor, city walk mall,<br/>
                    near halalpur bus stand,<br/>
                    Halalpura, Lalghati, Bhopal,<br/>
                    Madhya Pradesh 462030
                </p>
            </div>
            <div style={styles.column}>
                <nav style={styles.nav}>
                    <h4 style={styles.sitemapHeader}>SITEMAP</h4>
                    <a href="/" style={styles.link}>Home</a>
                    <a href="/about" style={styles.link}>About</a>
                    <a href="/Service" style={styles.link}>Facilities & Service</a>
                    <a href="/Facilities" style={styles.link}>Gallery</a>
                    <a href="/Contact" style={styles.link}>Contact</a>
                </nav>
                <p style={styles.contact}>
                    Phone: <span style={styles.contactDetail}>+91 7554945754</span><br/>
                    Mobile: <span style={styles.contactDetail}>+91 9111181199, +91 9425378309</span>
                </p>
            </div>
            <div style={styles.column}>
                <h3 style={styles.followUs}>Follow us</h3>
                <a href="https://www.facebook.com/infinityfitnessgymbhopal/" style={styles.socialLink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="4x" />
                </a>
                <a href="https://www.instagram.com/infinity_fitness_gym_bhopal/" style={styles.socialLink} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="4x" />
                </a>
                <p style={styles.contact}>
                    Email: <span style={styles.contactDetail}>infinityfitness24@gmail.com</span>
                </p>
            </div>
        </footer>
    );
};

const styles = {
    footer: {
        backgroundColor: '#141414',
        color: '#fff',
        padding: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textAlign: 'center',
        flexWrap: 'wrap',
        
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
        width: '100%',
        maxWidth: '300px', // Adjust the maximum width for smaller screens
        textAlign: 'left',
    },
    logo: {
        width: '150px',
        borderRadius: '50%',
        marginBottom: '10px',
        backgroundColor:'#000000'
    },
    addressHeader: {
        margin: '10px 0',
        fontWeight: 'bold',
        color: '#ff3f0c',
    },
    description: {
        maxWidth: '300px',
        fontSize: '16px',
        lineHeight: '1.5',
         // Address text color
    },
    contact: {
        fontSize: '16px',
        marginBottom: '10px',
        marginLeft:"70px",
        maxWidth: '300px',
        color: '#ff3f0c',
    },
    contactDetail: {
        color: '#ffffff', // Phone, mobile, and email text color
    },
    nav: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    sitemapHeader: {
        color: 'red',
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    link: {
        color: '#fff',
        margin: '5px 0',
        textDecoration: 'none',
        transition: 'color 0.3s',
    },
    linkHover: {
        color: '#ff3f0c', // Hover color for nav links
    },
    followUs: {
        color: 'red',
        marginBottom: '10px',
        fontWeight: 'bold',
    },
    socialLink: {
        color: '#ff3f0c',
        margin: '5px 0',
    },
    '@media (max-width: 768px)': {
        column: {
            maxWidth: '100%', // Adjust the maximum width for smaller screens
        },
    },
};

export default Footer;
