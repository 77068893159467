import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Facilities from './components/Facilities';
import Service from './components/Service';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Hello from './components/Hello';
import Servicedetail from './components/Servicedetail';
import './Global.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/service" element={<Service />} />
          <Route path="/service/:title" element={<Servicedetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/hello" element={<Hello />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
