import React from 'react';
import Form from './Form';
import contactus from '../Images/contactus.jpeg';

const Contact = () => {
    return (
        <div style={styles.container}>
            <div style={{ fontSize: '3rem', color: '#011624',fontFamily:"bold" }}>
                Contact Us
            </div>
            <div style={styles.contactInfo}>
                <h2>Contact Information</h2>
                <p><strong>Phone:</strong> +91 7554945754</p>
                <p><strong>Mobile:</strong> +91 9111181199,<br></br>+91 9425378309</p>
                <p><strong>Address:</strong> Infinity Fitness <br></br>
                4th floor, city walk mall, <br></br> near halalpur bus stand,<br></br> Halalpura, Lalghati, Bhopal,<br />Madhya Pradesh 462030</p>
                <p><strong>Email:</strong> infinityfitness24@gmail.com</p>
                {/* <Form /> */}
            </div>

            <div style={styles.mapContainer}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.147095861493!2d77.35654447532134!3d23.27410427899837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c67c1b98c11b3%3A0xef91eb422669819d!2sInfinity%20Fitness%20Gym!5e0!3m2!1sen!2sin!4v1718266818036!5m2!1sen!2sin" title="Google Map" style={styles.map}></iframe>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        backgroundImage: `url(${contactus})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
    },
    contactInfo: {
        textAlign: 'center',
        marginBottom: '20px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '600px', // Limit width for larger screens
        width: '100%', // Full width for smaller screens
    },
    mapContainer: {
        width: '100%',
        height: '400px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        overflow: 'hidden',
    },
    map: {
        width: '100%',
        height: '100%',
        border: '0',
    },
};

export default Contact;
