import React from "react";
import about from "../Images/about.jpeg";
import TrainHard from "../Images/TrainHard.webp";

const About = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>About Us</h1>
      <div style={styles.content}>
        <img src={TrainHard} alt="Gym" style={styles.image} />
        <div style={styles.text}>
          <h1 style={{ color: "red" }}>Welcome To</h1>
          <h1 style={{ color: "red" }}>INFINITY FITNESS GYM</h1>
          <p style={{ color: "black", fontSize: "18px" }}>
            INFINITY FITNESS GYM offers a modern and comprehensive fitness
            experience, perfectly suited to meet the needs of today's
            health-conscious individuals. In an era where sedentary lifestyles
            are prevalent due to desk jobs and digital engagements, regular
            exercise becomes crucial for maintaining both physical and mental
            health. INFINITY FITNESS GYM provides a variety of state-of-the-art
            equipment and diverse workout programs, ranging from strength
            training and cardio exercises to group fitness classes like yoga and
            spinning. These facilities and programs not only help in improving
            cardiovascular health, muscle strength, and flexibility but also
            play a significant role in reducing stress, boosting mood, and
            enhancing overall well-being. With personalized training plans and a
            supportive community, INFINITY FITNESS GYM ensures that members stay
            motivated and committed to their fitness goals, ultimately leading
            to a healthier, more active lifestyle.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${about})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    padding: "20px",
    color: "#000" // Set font color to black
  },
  heading: {
    textAlign: "center",
    marginBottom: "20px",
    color: "#000" // Set font color to
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  text: {
    textAlign: "center",
    maxWidth: "600px", // Adjust maximum width as needed
    marginBottom: "20px"
  },
  image: {
    width: "100%", // Image takes full width
    maxWidth: "600px", // Set maximum width for larger screens
    borderRadius: "8px"
  }
};

export default About;
