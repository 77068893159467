import React from 'react';
import djhdhv from '../Images/djhdhv.jpg';
import DSC_7814 from '../Images/DSC_7814.jpg';
// import yoga1 from '../Images/yoga1.jpg';
import { Link } from 'react-router-dom';
import DSC_6306 from '../Images/DSC_6306.jpg';
import Hello  from './Hello';
<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Sofia&effect=neon|outline|emboss|shadow-multiple"></link>


const Home = () => {
    return (
        <div>
            <Hello/>
          
            {/* Section 2: Stock Images */}
            <div style={styles.imageContainer}>
                <img src={djhdhv} alt="Gym" style={styles.image}  />
                <img src={DSC_7814} alt="Training" style={styles.image} />
                {/* <img src={yoga1} alt="Yoga" style={styles.image} />
                <img src={yoga1} alt="Yoga" style={styles.image} /> */}
            </div>
             {/* Section 4: Join Us Today */}
             <div style={styles.joinSection}>
                  <div style={styles.content}>
                <div style={styles.text}>
                    <h1 style={{color:"red"}}><i>Welcome To</i></h1>
                    <h1  style={{color:"red"}}><i>INFINITY FITNESS GYM</i></h1>
                    <p>
                    INFINITY FITNESS GYM offers a modern and comprehensive
                     fitness experience, perfectly suited to meet the needs of today's 
                     health-conscious individuals. In an era where sedentary lifestyles are 
                     prevalent due to desk jobs and digital engagements, regular exercise becomes 
                     crucial for maintaining both physical and mental health. INFINITY FITNESS GYM provides 
                     a variety of state-of-the-art equipment and diverse workout programs, ranging from strength 
                     training and cardio exercises to group fitness classes like yoga and spinning. These facilities
                      and programs not only help in improving.
                    </p>
                    <Link to="/about">
                        <button className='button-style'><span>Read More </span></button>
                    </Link>
                </div>
                
               
            </div>

             <Link to="/contact">
             <button className='button-style'><span>Join</span></button>
                </Link>
                {/* <h2 style={{font:25,color:'#de3009', fontFamily:'bold' }}>Join</h2> */}
                <div styles={{alignItems: 'center',}}>
                <h5>The central India's Friendliest Gym In Lalghati Bhopal!</h5>
                <p>Start your fitness journey with us!</p>
                
              
                </div>
                {/* <Link to="/contact">
                    <button style={styles.button}>Join Us Today</button>
                </Link> */}
            </div>

            {/* Section 3: Heading and Paragraph */}
            <div style={styles.content}>
                <div style={styles.text}>
                 
                    <p>We're committed to bringing you the best workout experience.<br/>
                #Elevate Your Fitness Journey!!<br/>
                With INFINITY FITNESS GYM<br/>
               with 8+ Years of experience</p>
               <h5 styles={{color:"red"}}>#Book your free! Demo Now</h5>
                   
                </div>
                
                <img src={DSC_6306} alt="About" style={styles.image2} />
            </div>

           {/* Section 4: Join Us Today */}                       
           <div style={styles.joinSection}>
              
                <div styles={styles.textArea}>
                    <p>INFINITY FITNESS GYM, located in Halalpura, Bhopal, is a premier fitness center with over 8 years of
                         experience in providing top-notch fitness solutions. Situated in the heart of Central India, INFINITY FITNESS GYM stands out as the FRIENDLIEST GYM in the region, offering a welcoming and supportive environment 
                          for fitness enthusiasts of all levels. With a team of experienced trainers and state-of-the-art facilities, 
                          INFINITY FITNESS GYM is dedicated to helping individuals achieve their fitness goals, whether it's weight loss,
                           muscle gain, or overall wellness. From personalized training programs to group classes and modern equipment,
                           INFINITY FITNESS GYM is committed to empowering its members on their fitness journey, making it a preferred 
                            choice for fitness enthusiasts in Bhopal and beyond.

                       For inquiries and memberships, please contact us.</p>
                {/* <h5>Join the central India's FRIENDLIEST GYM IN Lalghati Bhopal!</h5>
                <p>Start your fitness journey with us!</p>
                <p>We're committed to bringing you the best workout experience.<br/>
                #Elevate Your Fitness Journey!!<br/>
                With Infinity Fitness<br/>
               with 8+ Years of experience</p> */}
                {/* <h5 styles={{color:"red"}}>#Book your free! Demo Now</h5> */}
                </div>
                {/* <Link to="/contact">
                    <button style={styles.button}>Join Us Today</button>
                </Link> */}
            </div>


        </div>
    );
};

const styles = {
    imageContainer: {      
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        marginBottom: '20px',
        marginTop: '50px',
        gap:'10px',
        marginLeft:'20%',
       
        
    },
    image: {
        width: '30%',
        borderRadius: '8px',
        margin: '0 5px',
        border: '5px solid #ddd',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    image2: {
        width: '50%',
        borderRadius: '8px',
        margin: '0 5px',
        border: '5px solid #ddd',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        height: 'auto'
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    text: {
        textAlign: 'center',
        maxWidth: '800px',
        marginTop: '10px',
    },
    // button: {
    //     backgroundColor: '#00506e',
    //     color: '#ffffff',
    //     padding: '10px 20px',
    //     borderRadius: '15px',
    //     border: 'none',
    //     cursor: 'pointer',
    //     transition: 'background-color 0.3s ease',
    //     marginTop: '20px',
    // },  

    
        
    joinSection: {
        backgroundColor: '#1c1b1b',
        color: '#ffffff',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto', 
        
    },
    textArea: {
        maxWidth: '600px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
    },
    // Media Query for smaller screens
    '@media (max-width: 768px)': {
        imageContainer: {
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
        image: {
            width: '100%',
            marginBottom: '10px',
        },
    },
};

export default Home;