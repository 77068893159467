import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError("");
  };

  const submitData = async (e) => {
    e.preventDefault();
    if (!data.number.match(/^\d{10}$/)) {
      setError("Please enter a valid 10-digit number");
    } else {
      const response = await fetch(`${process.env.NEXT_PUBLIC_URL}/appointm ents`, {
        method: "POST",
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          number: data.number,
          message: data.message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const dataRes = await response.json();
        setData({
          name: "",
          email: "",
          number: "",
          message: "",
        });
        toast.success("Appointment Booked!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        toast.error("Failed to book appointment. Please try again later.");
      }
    }
  };

  return (
    <form autoComplete="off" style={{ maxWidth: '600px', margin: '0 auto', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: 'aquamarine' }}>
      <h2 style={{ marginBottom: '20px', fontSize: '24px', color: '#333' }}>Book an Appointment</h2>
      <label style={{ display: 'block', marginBottom: '10px', marginRight:'20px' }}>
        Your Name
        <input
          type="text"
          name="name"
          value={data.name}
          onChange={handleChange}
          required
          style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '15px' }}
        />
      </label>
      <label style={{ display: 'block', marginBottom: '10px' }}>
        Your Email
        <input
          type="email"
          name="email"
          value={data.email}
          onChange={handleChange}
          required
          style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '15px',marginRight:'20px'  }}
        />
      </label>
      <label style={{ display: 'block', marginBottom: '10px',marginRight:'20px',  }}>
        Your Number
        <input
          type="tel"
          name="number"
          value={data.number}
          pattern="[0-9]{10}"
          onChange={handleChange}
          required
          style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '15px',marginRight:'20px'  }}
        />
      </label>
      {error && <span className="error" style={{ color: 'red', marginBottom: '10px', display: 'block' }}>{error}</span>}
      <label style={{ display: 'block', marginBottom: '10px',marginRight:'20px'  }}>
        Your Message
        <textarea
          name="message"
          rows="3"
          value={data.message}
          onChange={handleChange}
          required
          style={{ width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '15px' }}
        ></textarea>
      </label>
      <button type="submit" className="btn-grad" style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer', transition: 'background-color 0.3s ease' }}>Submit</button>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </form>
  );
};

export default Form;
